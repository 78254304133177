import { useState, useEffect, memo } from "react";
import { Document, Page } from "react-pdf";
import "../split-pdf/split.css";

const PdfSplitViewer = ({
  fileUrl,
  forSplit = "",
  splitMode = "",
  rangeType = "",
  customRange = {},
  fixedRange = "",
  pagesToExtract = ""
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pagesToDisplay, setPagesToDisplay] = useState([]);

  useEffect(() => {
    if (pagesToExtract) {
       const pages = pagesToExtract
        .split(",")
        .map((page) => parseInt(page.trim(), 10))
        .filter((page) => page > 0 && page <= numPages);
      setPagesToDisplay(pages.length > 0 ? [pages] : []);
    } else if (rangeType === "custom" && customRange.from && customRange.to) {
      const from = parseInt(customRange.from, 10);
      const to = parseInt(customRange.to, 10);
      if (from > 0 && to >= from && to <= numPages) {
        setPagesToDisplay([Array.from({ length: to - from + 1 }, (_, i) => from + i)]);
      } else {
        setPagesToDisplay([]);
      }
    } else if (rangeType === "fixed" && fixedRange) {
      const pagesPerPair = parseInt(fixedRange, 10);
      if (pagesPerPair > 0 && numPages) {
        const pairs = [];
        for (let i = 1; i <= numPages; i += pagesPerPair) {
          pairs.push(
            Array.from({ length: pagesPerPair }, (_, idx) => i + idx).filter((page) => page <= numPages)
          );
        }
        setPagesToDisplay(pairs);
      } else {
        setPagesToDisplay([]);
      }
    } else {
      setPagesToDisplay([Array.from({ length: numPages }, (_, i) => i + 1)]);
    }
  }, [pagesToExtract, rangeType, customRange, fixedRange, numPages]);

  const renderSplitPages = () =>
    pagesToDisplay.map((pagePair, pairIndex) =>
      Array.isArray(pagePair) ? (
        <div
          key={pairIndex}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "15px",
            marginBottom: "20px",
          }}
        >
          {pagePair.map((pageNum) => (
            <div
              key={pageNum}
              style={{
                position: "relative",
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "10px",
                backgroundColor: "#f9f9f9",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                textAlign: "center",
              }}
            >
               <span
                style={{
                  // position: "absolute",
                 
                  right: "5px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "2px 6px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              >
                Page {pageNum}
              </span>
              <Page
                pageNumber={pageNum}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={150}
              />
            </div>
          ))}
        </div>
      ) : null
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "20px",
      }}
    >
      <Document
        file={fileUrl}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        {renderSplitPages()}
      </Document>
    </div>
  );
};

export default memo(PdfSplitViewer);
