import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import icon from "../../assets/Polygon 1.svg";
import lineDesign from "../../assets/Line 3.svg";
import onlinePdfConversion from "../../assets/onlinepdf Conversion.svg";
// import "../../App.css";
import "./home.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useNavigate } from "react-router-dom";
import { features } from "../../utils";
import Feature from "../../components/feature/Feature";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div
        style={{
          paddingTop: "80px",
          height: "calc(100vh)",
          overflowY: "auto",
          scrollbarWidth: "thin", 
          scrollbarColor: "lightgray transparent", 
        }}
      >
        {/* hero */}
        <div
          className="main-home-top"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "8%" }}
        >
          <p className="hero-heading">
            Unleash your PDF potential: <br /> Complete toolbox at your
            fingertips
          </p>
          <p className="hero-para text-muted">
            Every tool you need to use PDFs, at your fingertips. All are{" "}
            <span><a href=" /convertor/jpg-to-pdf" className="Link-tag">100% FREE</a></span> and easy to use! Merge, split, compress,
            convert, rotate, unlock and watermark PDFs with just a{" "}
            <span><a href=" /convertor/jpg-to-pdf" className="Link-tag">few clicks</a></span>.
          </p>
          <a href="/convertor/jpg-to-pdf" className="exploreNow-btn">
            Explore Now {<ArrowForwardIcon />}
          </a>
        </div>

        <div id="feature"></div>
        <div
          className="main-home-bottom"
          style={{ paddingLeft: "8%", paddingRight: "8%" }}
        >
          <Feature />
        </div>
        <div
          className="main-home-bottom"
          style={{ paddingLeft: "8%", paddingRight: "8%" }}
        >
          <button className="how-btn">How it works</button>
          <div>
            <div class="row col-lg-12 mt-4 mb-5">
              <div class="col-lg-6 step">
                <h1>Step-by-Step Guide</h1>
                <p className="text-muted flex-wrap" style={{fontWeight: '600'}} >
                  It's straightforward to convert your file to the desired
                  format with PDFplex for free. With our robust architecture and
                  user-friendly template, it is easy to navigate through the
                  process.
                </p>
              </div>

              <div class="col-lg-6 col-sm-12">
                <div className="d-flex gap-3">
                  <img src={icon} className="App-logo" alt="logo" />
                  <h1 className="third-component-heading">Choose a File</h1>
                </div>
                <div class="d-flex" style={{ marginLeft: "10px" }}>
                  <img src={lineDesign} className="App-logo" alt="logo" />
                  <div className="text-muted  ps-4" style={{fontWeight: '600'}} >
                    First, you choose the file you want to convert the file
                    format for. Click on Upload and select the file you want to
                    convert.
                  </div>
                </div>
                <div class="">
                  <div className="d-flex gap-3">
                    <img src={icon} className="App-logo" alt="logo" />
                    <h1 className="third-component-heading">
                      Convert in just one click
                    </h1>
                  </div>
                  <div class="d-flex" style={{ marginLeft: "10px" }}>
                    <img src={lineDesign} className="App-logo" alt="logo" />
                    <div className="text-muted ps-4" style={{fontWeight: '600'}} >
                      After you have selected the file our application will
                      convert the file format of your file in seconds. You can
                      rest assured that the size and quality of your file will
                      remain the same as the original.{" "}
                    </div>
                  </div>
                </div>

                <div className="d-flex gap-3">
                  <img src={icon} className="App-logo" alt="logo" />
                  <h1 className="third-component-heading">
                    Download your document
                  </h1>
                </div>
                <div class="d-flex">
                  <div className="text-muted  ps-5" style={{fontWeight: '600'}} >
                    As soon as the file format is converted a button will appear
                    that you can press to download your file.
                  </div>
                </div>
              </div>
            </div>
            <div className="row col-lg-12 mt-5 pt-5 "   style={{ margintop: "8%"}}>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div className="col-lg-8 col-md-12">
                  <h1 className="heading-tools">
                    Your All-in-One Online <span>PDF Tools</span>
                  </h1>
                  <span className="text-muted" style={{fontWeight: '600'}} >
                    We build PDF Tools to make your life easier. Access an
                    easy-to-use PDF Solution with all the features you need:
                    Convert files to PDF, from PDF, merge, fill, sign, and
                    compress PDFs within a few clicks.
                  </span>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <img
                  src={onlinePdfConversion}
                  className="img-fluid"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
        <div>{<Footer />}</div>
      </div>
    </div>
  );
};

export default Home;
