import { useState, useEffect, useRef, memo } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfViewer = ({
  fileUrl,
  watermarkText = "",
  watermarkColor = "rgba(0, 0, 0, 0.5)",
  fontSize = 24,
  opacity = 0.5,
  degree = 0,
  uploadedImage = null,
  isSign = false,
  forwatermark = "",
  position = "center",
}) => {
  const canvasRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    console.log("Position received in PdfViewer:", position);
  }, [position]);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const canvasWidth = 500;
    const canvasHeight = 680;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const getPositionCoordinates = () => {
      let x = canvas.width / 2;
      let y = canvas.height / 2;

      switch (position) {
        case "top-left":
          x = 50;
          y = fontSize;
          break;
        case "top-center":
          x = canvas.width / 2;
          y = fontSize;
          break;
        case "top-right":
          x = canvas.width - 50;
          y = fontSize;
          break;
        case "center-left":
          x = 50;
          y = canvas.height / 2;
          break;
        case "center-right":
          x = canvas.width - 50;
          y = canvas.height / 2;
          break;
        case "bottom-left":
          x = 50;
          y = canvas.height - fontSize;
          break;
        case "bottom-center":
          x = canvas.width / 2;
          y = canvas.height - fontSize;
          break;
        case "bottom-right":
          x = canvas.width - 50;
          y = canvas.height - fontSize;
          break;
        default:
          x = canvas.width / 2;
          y = canvas.height / 2;
          break;
      }
      return { x, y };
    };

    if (watermarkText) {
      const { x, y } = getPositionCoordinates();
      ctx.save();
      ctx.globalAlpha = opacity;
      ctx.fillStyle = watermarkColor;
      ctx.font = `${fontSize}px Arial`;
      ctx.textAlign = "center";
      ctx.translate(x, y);
      ctx.rotate((degree * Math.PI) / 180);
      ctx.fillText(watermarkText, 0, 0);
      ctx.restore();
    } else if (uploadedImage) {
      const { x, y } = getPositionCoordinates();
      const img = new Image();
      img.src = uploadedImage;
      img.onload = () => {
        ctx.save();
        ctx.globalAlpha = opacity;
        ctx.translate(x, y);
        ctx.rotate((degree * Math.PI) / 180);
        const imgWidth = 100;
        const imgHeight = 100;
        ctx.drawImage(img, -imgWidth / 2, -imgHeight / 2, imgWidth, imgHeight);
        ctx.restore();
      };
    }
  }, [watermarkText, watermarkColor, fontSize, opacity, degree, uploadedImage, position]);

  return (
    <div style={{ display: "flex", margin: "12px", gap: "20px", width: "100%" }}>
      {forwatermark ? (
        <div style={{ flex: 1, position: "relative" }}>
          <Document
            file={fileUrl}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page
              pageNumber={1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={500}
            />
          </Document>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 150,
              pointerEvents: "none",
            }}
          >
            <canvas ref={canvasRef} />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "120px",
            height: "100vh",
            overflowY: "auto",
            border: "1px solid #ddd",
            padding: "5px",
          }}
        >
          <Document
            file={fileUrl}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            {Array.from(new Array(numPages || 0), (el, index) => (
              <div
                key={index}
                style={{ marginBottom: "10px", cursor: "pointer" }}
                onClick={() => setCurrentPage(index + 1)}
              >
                <Page
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={100}
                />
              </div>
            ))}
          </Document>
        </div>
      )}

      {!forwatermark && (
        <div style={{ flex: 1 }}>
          <h5>{!isSign && `Editable content on Page-${currentPage}`}</h5>
          <Document
            file={fileUrl}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page
              pageNumber={currentPage}
              renderTextLayer={!isSign}
              renderAnnotationLayer={false}
            />
          </Document>
        </div>
      )}
    </div>
  );
};

export default memo(PdfViewer);
