import { useState, useEffect, memo } from "react";
import { Document, Page } from "react-pdf";
import "../split-pdf/split.css";

const PdfViewer = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState(null);

  const renderPages = () =>
    Array.from({ length: numPages }, (_, i) => i + 1).map((pageNum) => (
      <div
        key={pageNum}
        style={{
          position: "relative",
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "10px",
          backgroundColor: "#f9f9f9",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        <span
          style={{
            right: "5px",
            fontSize: "12px",
            fontWeight: "bold",
            backgroundColor: "#fff",
            borderRadius: "4px",
            padding: "2px 6px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          Page {pageNum}
        </span>
        <Page
          pageNumber={pageNum}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          width={150}
        />
      </div>
    ));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "20px",
      }}
    >
      <Document file={fileUrl} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
        {numPages && renderPages()}
      </Document>
    </div>
  );
};

export default memo(PdfViewer);
