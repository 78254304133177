import React, { useState } from "react";
import Imagebox from "../pdfConveter/Imagebox";
import rightarrow from "../../assets/rightarrow.png";
import FileHeader from "../file-header/FileHeader";
import FileFooter from "../file-footer/FileFooter";
import Footer from "../footer/Footer";
import noMargin from "../../assets/marginsetIcon.svg";
import bigimg from "../../assets/big-margin-icon.png";
import smallMargin from "../../assets/small-margin-icon.svg";
import { api } from "../../api/api";
import Loader from "../loader/Loader";
import { replaceId, MAX_FILE_SIZE } from "../../shared";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { IconGripVertical } from "@tabler/icons-react";
import InputWithIcons from "../InputWithIcons";
import PdfViewer from "./signView";
import InputWithButton from "../InputWithButton";
import AddSignee from "../modals/AddSignee";
import AddSignature from "../modals/AddSignature";

const SignPdf = ({ id, isSingle }) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signature, setSignature] = useState("");
  const [signPosition, setSignPosition] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState("");
  const navigate = useNavigate();
  const convertFile = async (event) => {
    setMessage("");
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!files) {
      toast.error("Please select a file first.");
      return;
    }

    if (files?.length > 10) {
      toast.error("File must be less than or equal to 10.");
      return;
    }

    const invalidFiles = files.filter((file) => file.size > MAX_FILE_SIZE);
    if (invalidFiles.length > 0) {
      toast.error("One or more files exceed the 10 MB limit.");
      return;
    }

    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("file", files[0]);

    // Function to check if a string is base64 encoded
    function isBase64(str) {
      const base64Pattern =
        /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-+.]+);base64,([A-Za-z0-9+/=]+)$/;
      return base64Pattern.test(str);
    }

    // Function to convert base64 string to a Blob
    function base64ToBlob(base64, contentType = "") {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    }

     if (isBase64(signature)) {
       const matches = signature.match(
        /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-+.]+);base64,(.+)$/
      );
      if (matches && matches.length === 3) {
        const contentType = matches[1]; 
        const base64Data = matches[2]; // Base64 string without the prefix
        const blob = base64ToBlob(base64Data, contentType); // Convert to Blob
        formDataToSend.append("signature", blob, "signature.png"); // Append Blob to FormData with filename
      }
    } else {
      // If not base64, append as it is
      formDataToSend.append("signature", signature);
    }
    formDataToSend.append("signPosition", signPosition);
    formDataToSend.append("pageNumber", pageNumber);

    try {
      const response = await api().post(`${id}/single`, formDataToSend);
      setLoading(false);
      if (response?.data?.meta.status) {
        navigate("/convertor/file-ready", {
          state: { fileUrl: response?.data?.data?.url },
        });
      }
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };
  return (
    <>
      {files.length === 0 ? (
        <>
          <FileHeader id={id} />
          <div className="d-flex justify-content-center">
            <div className="main-box d-flex align-items-center justify-content-center mt-5">
              <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
                <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                  <h3>Drop your file here</h3>
                  <span>or</span>
                  <div>
                    <label
                      id="idlabel"
                      className="mdl-button mdl-js-button mdl-button--raised"
                    >
                      <input
                        type="file"
                        accept=".pdf"
                        multiple={!isSingle}
                        id="exampleFormControlFile1"
                        onChange={convertFile}
                      />
                      Upload a file
                    </label>
                  </div>
                </div>
                <div>
                  <p className="text-muted mt-3">
                    Maximum size allowed is 10MB. Supported formats are :{" "}
                    {id.split("-")[0]}
                  </p>
                  {message && (
                    <div className="text-success" style={{ color: "green" }}>
                      {message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <Loader title={"Updating pdf "} />
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="w-100 d-flex justify-content-between main-container flex-wrap pt-5">
                <div className="images-side ml-5">
                  <PdfViewer
                    fileUrl={URL.createObjectURL(files[0])}
                    isSign={true}
                    {...{ numPages, setNumPages }}
                  />
                  {/* <Imagebox files={files} setFiles={setFiles} isSingle={isSingle} /> */}
                </div>
                <div className="converter-side p-3"  style={{  height: "100%" }}>
                  <h1 className="text-center">
                    {id.replace(/-/g, " ").toUpperCase()}
                  </h1>
                  <hr style={{ borderColor: "#FF9291", mx: "-16px" }} />
                  <button
                    className="w-100"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#addSigneeModal"
                    style={{
                      height: "70px",
                      fontSize: "22px",
                      backgroundColor: "#FF9291",
                      borderRadius: "8px",
                      border: "0px",
                    }}
                  >
                    Invite Signees
                  </button>
                  <button
                    className="w-100 my-3"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#addSignatureModal"
                    style={{
                      height: "70px",
                      fontSize: "22px",
                      backgroundColor: "#FF9291",
                      borderRadius: "8px",
                      border: "0px",
                    }}
                  >
                    Add Your Signature
                  </button>
                  <select
                    className="w-100 rounded border border-secondary bg-transparent"
                    style={{ height: "40px" }}
                    value={signPosition}
                    onChange={(e) => setSignPosition(e.target.value)}
                  >
                    <option disabled value={""}>
                      Select Position
                    </option>
                    <option value={"bottomRight"}>Bottom Right</option>
                    <option value={"bottomLeft"}>Bottom Left</option>
                  </select>
                  <select
                    className="w-100 rounded border border-secondary bg-transparent mt-3"
                    style={{ height: "40px" }}
                    value={pageNumber}
                    onChange={(e) => setPageNumber(e.target.value)}
                  >
                    <option disabled value={""}>
                      Select Page Number
                    </option>
                    {Array.from(new Array(numPages), (el, index) => (
                      <option value={index + 1}>{index + 1}</option>
                    ))}
                  </select>
                  <div className="border border-secondary rounded p-2 mt-3">
                    <p>Your added signature:</p>
                    {signature ? (
                      <img
                        src={
                          signature instanceof File
                            ? URL.createObjectURL(signature)
                            : signature
                        }
                        width={"100%"}
                      />
                    ) : (
                      <p className="text-center text-sm fw-light">
                        No signature added
                      </p>
                    )}
                  </div>
                  
                  <AddSignee />
                  <AddSignature setSignature={setSignature} />
                  <div>
                    <button
                      type="submit"
                      className="btn-convert w-100 mt-5"
                      disabled={!signature || !signPosition || !pageNumber}
                    >
                      Sign PDF{" "}
                      <img src={rightarrow} alt="" width="30" height="18" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </>
      )}
      {files.length === 0 ? (
        <>
          <FileFooter id={id} />
          <Footer />
        </>
      ) : (
        <></>
      )}
      <ToastContainer />
    </>
  );
};

export default SignPdf;
