import React, { useRef } from "react";
import Header from "../../components/header/Header";
import "./file-ready.css";
import Continue from "../../components/continue-to/Continue";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faShareNodes, faRotate } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from "qrcode.react";

const FileReady = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const qrCodeRef = useRef(null);

    const handleGoBack = () => {
        navigate(-1);  
    };
    
    const downloadHandler = () => {
        const fileUrl = location.state.fileUrl;
        const link = document.createElement('a');
        link.href = location.state.fileUrl;
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const fileUrl = location.state.fileUrl;  

    const downloadQRCode = () => {
        const qrCodeCanvas = qrCodeRef.current.querySelector("canvas");
        const qrCodeDataUrl = qrCodeCanvas.toDataURL("image/jpeg", 1.0); // Convert to JPEG

        // Create a link to download the image
        const link = document.createElement('a');
        link.href = qrCodeDataUrl;
        link.download = 'qr-code.jpg';
        link.click();
    };

    return (
        <>
            <Header />

            <div className="main-top uni-padding d-flex gap-5">
                <div className="col-lg-12">
                    <div className="d-flex justify-content-center text-center">
                        <div className="file-sec">
                            <h1>File Ready</h1>
                            <p>2024-01-30 220532 <span className="text-muted">.dox</span></p>
                            <button className="btn-download" onClick={downloadHandler}>
                                <FontAwesomeIcon className="me-2" icon={faDownload} />Download
                            </button><br />
                            <button className="mt-2 btn-share">
                                <FontAwesomeIcon className="me-2" icon={faShareNodes} />Share
                            </button>
                            <p className="mt-4 text-muted" style={{textAlign:"center"}} onClick={handleGoBack}>
                                <FontAwesomeIcon className="me-2" icon={faRotate} />Start Over
                            </p>

                            <div className="qr-code" ref={qrCodeRef}>
                                <h4>Scan to Access the File:</h4>
                                 <QRCodeCanvas 
                                    value={fileUrl} 
                                    size={128} 
                                    includeMargin={true} 
                                />
                                <br />
                                 <button className="mt-2 btn-download" onClick={downloadQRCode}>
                                    <FontAwesomeIcon className="me-2" icon={faDownload} />Download QR Code
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Continue />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FileReady;
