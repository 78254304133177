import React, { useState } from 'react'
import FileHeader from '../file-header/FileHeader';
import Imagebox from '../pdfConveter/Imagebox';
import FileFooter from '../file-footer/FileFooter';
import Footer from '../footer/Footer';
import rightarrow from "../../assets/rightarrow.png";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../../api/api';
import { Spinner } from 'react-bootstrap';
import { replaceId } from '../../shared';
import Loader from '../loader/Loader';
import "../../rotate-pdf.css"


const RotatePdf = ({ id, isSingle }) => {
  const [message, setMessage] = useState("");
 
  const [files, setFiles] = useState([]);
  const [rotationAngle, setRotationAngle] = useState("0")
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleResetAll = () => {
    setRotationAngle('0');   
   setFiles([]);            
  };
  const convertFile = (event) => {
    setMessage("");
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);  
  };

  const handleSubmit = async () => {
    if (files.length === 0) {
      toast.error("Please select at least one file.");
      return;
    }

    if (files.length > 10) {
      toast.error("Number of files must be less than or equal to 10.");
      return;
    }
   
    
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append(`file`, files[0]);
    formDataToSend.append(`rotationAngle`, rotationAngle);
    try {
      const response = await api().post('rotate-pdf/single', formDataToSend);
      setLoading(false);

      if (response?.data?.meta?.status) {
        navigate("/convertor/file-ready", { state: { fileUrl: response?.data?.data?.url } });
      } else {
        toast.error("Failed to rotate PDF file.");
      }
    } catch (error) {
      console.error("Error ===> ", error);
      toast.error("An error occurred during file rotation.");
      setLoading(false);
    }
  };

  return (
    <>
      {files.length === 0 ? <>
        <FileHeader id={id} />
        <div className="d-flex justify-content-center">
          <div className="main-box d-flex align-items-center justify-content-center mt-5">
            <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
              <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                <h3>Drop your file here</h3>
                <span>or</span>
                <div>
                  <label
                    id="idlabel"
                    className="mdl-button mdl-js-button mdl-button--raised"
                  >
                    <input
                      type="file"
                      multiple={!isSingle}
                      accept=".pdf"
                      id="exampleFormControlFile1"
                      onChange={convertFile}
                    />
                    Upload a file
                  </label>
                </div>
              </div>
              <div>
                <p className="text-muted mt-3">
                  Maximum size allowed is 10MB. Supported formats are : {id.split("-")[0]}
                </p>
                {message && (
                  <div className="text-success" style={{ color: "green" }}>
                    {message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </> :
      <>
      {
        loading? <Loader title={"Converting " + replaceId(id)} />:
        <div className='w-100 d-flex justify-content-between main-container flex-wrap'>
         <div className='images-side ml-5'>
          <Imagebox files={files} setFiles={setFiles} type="ppt" isSingle={isSingle} />
        </div>
      
         <div className='converter-side p-3 d-flex flex-column justify-content-between' style={{ height: '88vh' }}>
           <h1 className='text-center'>{id.replace(/-/g, ' ').toUpperCase()}</h1>
      
           <div className='mt-3 d-flex justify-content-between align-items-center'>
             <p className='hello mb-0' style={{ width: '25%' }}>Rotation:</p>
      
             <div className='d-flex align-items-center w-100'>
             <select
  className="form-select custom-select w-100"
  name="rotation"
  value={rotationAngle}
  onChange={(e) => setRotationAngle(e.target.value)}
  style={{
     marginRight: '10px',
    border: '1px solid #ced4da',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    padding: '8px 12px',
    color: '#495057',
    backgroundColor: '#fff',
    appearance: 'none', // Remove default dropdown style
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
  }}
>
  <option value="0">0°</option>
  <option value="90">90°</option>
  <option value="180">180°</option>
  <option value="270">270°</option>
</select>

               <i 
                className="fas fa-undo" 
                style={{ cursor: 'pointer', fontSize: '20px', color: '#6c757d', marginLeft: '10px' }}
                onClick={() => setRotationAngle("0")} // Reset value to 0°
                title="Reset Rotation"
              ></i>
            </div>
          </div>
      
          {/* Convert Button */}
          <div>
            <button className='btn-convert w-100' onClick={handleSubmit} disabled={loading}>
              {loading ? <Spinner /> : <span>Rotate PDF <img src={rightarrow} alt="" width="30" height="18" /></span>}
            </button>
          </div>
        </div>
      </div>
      
      
      }
      </>

      }
      {files.length === 0 ? <>
        <FileFooter id={id} />
        <Footer />
      </> : <> </>}
    </>
  )
}

export default RotatePdf