import React, { useState } from 'react';
import FileHeader from '../file-header/FileHeader';
import Imagebox from '../pdfConveter/Imagebox';
import FileFooter from '../file-footer/FileFooter';
import Footer from '../footer/Footer';
import rightarrow from "../../assets/rightarrow.png";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../../api/api';
import Loader from '../loader/Loader';
import Texts from '../add-watermark/text.jsx';
import A from "../../assets/A.png";
import Frames from "../../assets/Frame 18.png";
import boxe from "../../assets/box.png";
import "../../components/add-watermark/tool.css"

import PdfViewer from "../PdfViewr";

const AddWatermark = ({ id, isSingle }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [watermarkText, setWatermarkText] = useState('');
  const [fontSize, setFontSize] = useState(50);
  const [opacity, setOpacity] = useState(0.3);
  const [watermarkColor, setWatermarkColor] = useState('#FF0000');
  const [degree, setDegree] = useState(45);
  const [activeOption, setActiveOption] = useState(null); // Tracks the active option (text or image)
  const [uploadedImage, setUploadedImage] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState("");
  const navigate = useNavigate();
  const [positionIndex, setPositionIndex] = useState(5);

  const [textStyles, setTextStyles] = useState({
    italic: false,
    bold: false,
    underline: false,
    alignment: "left",
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const positions = {
    0: "top-left",
    1: "top-center",
    2: "top-right",
    3: "center-left",
    4: "center",
    5: "center-right",
    6: "bottom-left",
    7: "bottom-center",
    8: "bottom-right",
  };

  const handleCellClick = (index) => {
    setPosition(gridPositions[index]);
    setPositionIndex(index + 1);  
  };
 
  
  const gridPositions = [
    { x: 0, y: 0 }, { x: 50, y: 0 }, { x: 100, y: 0 },
    { x: 0, y: 50 }, { x: 50, y: 50 }, { x: 100, y: 50 },
    { x: 0, y: 100 }, { x: 50, y: 100 }, { x: 100, y: 100 },
  ];
  
  const convertFile = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setUploadedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      toast.error("Please select at least one file.");
      return;
    }
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append('file', files[0]);
    formDataToSend.append('watermarkText', watermarkText);
    formDataToSend.append('fontSize', fontSize);
    formDataToSend.append('opacity', opacity);
    formDataToSend.append('watermarkColor', watermarkColor);
    formDataToSend.append('degree', degree);  
    formDataToSend.append('positionIndex', positionIndex);
    try {
      const response = await api().post('add-watermark/single', formDataToSend);
      setLoading(false);
      if (response?.data?.meta?.status) {
        navigate("/convertor/file-ready", { state: { fileUrl: response?.data?.data?.url } });
      } else {
        toast.error("Failed to add watermark to the PDF file.");
      }
    } catch (error) {
      toast.error("An error occurred during the watermarking process.");
      setLoading(false);
    }
  };

  return (
    <>
      {files.length === 0 ? (
        <>
          <FileHeader id={id} />
          <div className="d-flex justify-content-center">
            <div className="main-box d-flex align-items-center justify-content-center mt-5">
              <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
                <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                  <h3>Drop your file here</h3>
                  <span>or</span>
                  <div>
                    <label id="idlabel" className="mdl-button mdl-js-button mdl-button--raised">
                      <input
                        type="file"
                        multiple={!isSingle}
                        accept=".pdf"
                        id="exampleFormControlFile1"
                        onChange={convertFile}
                      />
                      Upload a file
                    </label>
                  </div>
                </div>
                <p className="text-muted mt-3">
                  Maximum size allowed is 10MB. Supported formats are: {id.split("-")[0]}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <Loader title={"Processing " + id.replace(/-/g, ' ').toUpperCase()} />
          ) : (
            <div className='w-100 d-flex justify-content-between main-container flex-wrap he pdfdesign' style={{height:"40vh"}}>
              <div className='images-side ml-5' style={{ height:"40vh" }}>
              <PdfViewer
  fileUrl={URL.createObjectURL(files[0])}
  currentPage={1}
  watermarkText={activeOption === "text" ? watermarkText : ""}
  watermarkColor={watermarkColor}
  fontSize={fontSize}
  opacity={opacity}
  degree={degree}
  forwatermark="Ab"
  uploadedImage={activeOption === "image" ? uploadedImage : null}
  position={positions[positionIndex - 1] || 'None'}  
/>


           </div>
              <div className='converter-side p-3 d-flex flex-column justify-content-between' style={{ height: '88vh' }}>
                <form onSubmit={handleSubmit} className='d-flex flex-column gap-2'>
                
              
                <h1 className='text-center'>{id.replace(/-/g, ' ').toUpperCase()}</h1>
                <hr className="my-3" />
                 
                  <div className="row text-center my-4">
                    <div
                      className="col-6 d-flex flex-column align-items-center"
                      onClick={() => setActiveOption(activeOption === 'text' ? null : 'text')}
                    >
                      <img src={A} alt="Add Text" className="icon mb-2" style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
                      <p className="mb-0">Add Text</p>
                    </div>
                    <div
                      className="col-6 d-flex flex-column align-items-center"
                      onClick={() => setActiveOption(activeOption === 'image' ? null : 'image')}
                    >
                      <img src={Frames} alt="Add Image" className="icon mb-2" style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
                      <p className="mb-0">Add Image</p>
                    </div>
                  </div>
                  <hr className="my-3" />
                  {activeOption === 'text' && (
  <div className="form-group" style={{ display: "block" }}>
    <label>Text</label>
    <input
      className="form-control"
      value={watermarkText}
      onChange={(e) => setWatermarkText(e.target.value)}
      style={{
        fontStyle: textStyles.italic ? "italic" : "normal",
        fontWeight: textStyles.bold ? "bold" : "normal",
        textDecoration: textStyles.underline ? "underline" : "none",
        textAlign: textStyles.alignment,
      }}
    />
   
  </div>
)}

                  {activeOption === 'image' && (
                    <div className="form-group" style={{display:"block"}} >
                      <label>Upload Image</label>
                      <input type="file" accept="image/*" className="form-control" onChange={handleImageUpload}   style={{display:"block"}}/>
                      {uploadedImage && (
                     <div className="mt-2 d-flex justify-content-center">
                     <img className='imgdisplay'
                       src={uploadedImage}
                       alt="Preview"
                       style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                     />
                   </div>
                   
                      )}
                    </div>
                  )}
 <div
      className="position-selector"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <table className="table table-bordered" style={{ width: "50%", backgroundColor: "white" }}>
        <tbody>
          {[0, 1, 2].map((rowIndex) => (
            <tr key={rowIndex}>
              {[0, 1, 2].map((colIndex) => {
                const index = rowIndex * 3 + colIndex;
                const isSelected = positionIndex === index + 1;
                return (
                  <td
                    key={index}
                    onClick={() => handleCellClick(index)}
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                      backgroundColor: isSelected ? "#f1b6bb" : "transparent",
                    }}
                  >
                    {isSelected && (
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          backgroundColor: "red",
                          borderRadius: "50%",
                          margin: "auto",
                        }}
                      />
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <small className="text-center d-block mt-2">Click a cell to set the position</small>
    </div>




                  <div className="form-group d-flex align-items-center justify-content-between">
                  <div style={{ width: '45%' }}>
  <label>Rotate Degree</label>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      type="range"
      min="0"
      max="360"
      step="1"
      value={degree}
      onChange={(e) => setDegree(e.target.value)}
      style={{ width: '100%' }}
    />
    <span style={{ marginLeft: '10px' }}>{degree}°</span>
  </div>
</div>

                    <div style={{ width: '45%' }}>
  <label>Transparency</label>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      type="range"
      min="0"
      max="1"
      step="0.01"
      value={opacity}
      onChange={(e) => setOpacity(parseFloat(e.target.value))}
      style={{ width: '100%' }}
    />
    <span style={{ marginLeft: '10px' }}>{(opacity * 100).toFixed(0)}%</span>
  </div>
</div>

                  </div>
                  <hr className="my-3" />
                  <button type="submit" className='btn-convert w-100'>
                    Add Watermark <img src={rightarrow} alt="" width="30" height="18" />
                  </button>
                </form>
              </div>
            </div>
          )}
        </>
      )}
      {files.length === 0 && (
        <>
      
        </>
      )}
    </>
  );
};

export default AddWatermark;
