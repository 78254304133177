import React, { useState } from "react";
import Imagebox from "../pdfConveter/Imagebox";
import rightarrow from "../../assets/rightarrow.png";
import FileHeader from "../file-header/FileHeader";
import FileFooter from "../file-footer/FileFooter";
import Footer from "../footer/Footer";
import { api } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const UnlockPdf = ({ id }) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(""); // Password state
  const navigate = useNavigate();

  const convertFile = async () => {
    setMessage("");

    if (files.length === 0) {
      toast.error("Please upload a file before unlocking.");
      return;
    }

    if (!password) {
      toast.error("Please enter the password.");
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("password", password);  

    try {
      setLoading(true);
      const response = await api().post(`remove-pdf-password/single`, formData);

      setLoading(false);
      if (response?.data?.meta?.status) {
        navigate("/convertor/file-ready", {
          state: { fileUrl: response?.data?.data?.url },
        });
      } else {
        toast.error("Failed to remove the PDF password.");
      }
    } catch (error) {
      toast.error("An error occurred during the process.");
      setLoading(false);
    }
  };

  return (
    <>
      {files.length === 0 ? (
        <>
          <FileHeader id={id} />
          <div className="d-flex justify-content-center">
            <div className="main-box d-flex align-items-center justify-content-center mt-5">
              <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
                <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                  <h3>Drop your file here</h3>
                  <span>or</span>
                  <div>
                    <label
                      id="idlabel"
                      className="mdl-button mdl-js-button mdl-button--raised"
                    >
                      <input
                        type="file"
                        multiple
                        accept="application/pdf"
                        id="exampleFormControlFile1"
                        onChange={(event) => {
                          const selectedFiles = Array.from(event.target.files);
                          setFiles((prevFiles) => [
                            ...prevFiles,
                            ...selectedFiles,
                          ]);
                        }}
                      />
                      Upload a file
                    </label>
                  </div>
                </div>
                <div>
                  <p className="text-muted mt-3">
                    Maximum size allowed is 10MB. Supported formats are :{" "}
                    {id.split("-")[0]}
                  </p>
                  {message && (
                    <div className="text-success" style={{ color: "green" }}>
                      {message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-100 d-flex justify-content-between main-container flex-wrap">
            <div className="images-side ml-5">
              <Imagebox files={files} setFiles={setFiles} type="pdf" />
            </div>
            <div
              className="converter-side p-3 d-flex flex-column justify-content-between"
              style={{ height: "600px" }}
            >
              <h1 className="text-center">
                {id.replace(/-/g, " ").toUpperCase()}
              </h1>
              <div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Enter Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Enter PDF password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button
                  className="btn-convert w-100"
                  onClick={convertFile}
                  disabled={loading}
                >
                  {loading ? "Unlocking..." : "Unlock PDF"}
                  <img src={rightarrow} alt="" width="30" height="18" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {files.length === 0 ? (
        <>
          <FileFooter id={id} />
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default UnlockPdf;
