import axios from "axios";
export const api = () => {
  return axios.create({
    // baseURL: "http://localhost:3000/public/api/",
    baseURL: "https://api.pdfplex.com/public/api/",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

export const apiJson = () => {
  return axios.create({
    // baseURL: "http://localhost:3000/public/api/",
    baseURL: "https://api.pdfplex.com/public/api/",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
