import React from "react";
import "../add-watermark/tool.css"; // Add CSS styles here

const TextToolbar = () => {
  return (
    <div className="text-toolbar">
      <button className="toolbar-button">
        <i className="toolbar-icon italic">I</i>
      </button>
      <button className="toolbar-button">
        <i className="toolbar-icon bold">B</i>
      </button>
      <button className="toolbar-button">
        <i className="toolbar-icon underline">U</i>
      </button>
      <button className="toolbar-button">
        <i className="toolbar-icon link">🔗</i>
      </button>
      <button className="toolbar-button">
        <i className="toolbar-icon block">■</i>
      </button>
      <button className="toolbar-button">
        <i className="toolbar-icon align-left">≡</i>
      </button>
      <button className="toolbar-button">
        <i className="toolbar-icon align-center">≡</i>
      </button>
      <button className="toolbar-button">
        <i className="toolbar-icon align-right">≡</i>
      </button>
      <button className="toolbar-button">
        <i className="toolbar-icon justify">≡</i>
      </button>
    </div>
  );
};

export default TextToolbar;
