import React, { useState } from "react";
import FileHeader from "../file-header/FileHeader";
import Imagebox from "../pdfConveter/Imagebox";
import FileFooter from "../file-footer/FileFooter";
import Footer from "../footer/Footer";
import rightarrow from "../../assets/rightarrow.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../api/api";
import { Spinner } from "react-bootstrap";
import { replaceId } from "../../shared";
import Loader from "../loader/Loader";
import "../split-pdf/split.css";
import split from "../../assets/css_1157163.png";
import split2 from "../../assets/arrow_15856163.png";
import PdfViewer from "./SplitpdfView";

const SplitPdf = ({ id, isSingle }) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [splitMode, setSplitMode] = useState("");
  const [rangeType, setRangeType] = useState("");
  const [customRange, setCustomRange] = useState({ from: "", to: "" });
  const [fixedRange, setFixedRange] = useState("");
  const [extractType, setExtractType] = useState("");
  const [pagesToExtract, setPagesToExtract] = useState("");
  const [numPages, setNumPages] = useState(null);

  const navigate = useNavigate();

  const convertFile = (event) => {
    setMessage("");
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]); // Accumulate selected files
  };

  const handleSubmit = async () => {
    if (files.length === 0) {
      toast.error("Please select at least one file.");
      return;
    }

    if (files.length > 10) {
      toast.error("Number of files must be less than or equal to 10.");
      return;
    }

    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append(`file`, files[0]);
    formDataToSend.append("splitMode", splitMode);
    formDataToSend.append("rangeType", rangeType);
    if (rangeType === "custom") {
      formDataToSend.append("from", customRange.from);
      formDataToSend.append("to", customRange.to);
    } else if (rangeType === "fixed") {
      formDataToSend.append("fixedRange", fixedRange);
    }

    try {
      const response = await api().post("split-pdf/single", formDataToSend);
      setLoading(false);

      if (response?.data?.meta?.status) {
        navigate("/convertor/file-ready", {
          state: { fileUrl: response?.data?.data?.url },
        });
      } else {
        toast.error("Failed to split PDF file.");
      }
    } catch (error) {
      console.error("Error ===> ", error);
      toast.error("An error occurred during file split.");
      setLoading(false);
    }
  };

  return (
    <>
      {files.length === 0 ? (
        <>
          <FileHeader id={id} />
          <div className="d-flex justify-content-center">
            <div className="main-box d-flex align-items-center justify-content-center mt-5">
              <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
                <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                  <h3>Drop your file here</h3>
                  <span>or</span>
                  <div>
                    <label
                      id="idlabel"
                      className="mdl-button mdl-js-button mdl-button--raised"
                    >
                      <input
                        type="file"
                        multiple={!isSingle}
                        accept=".pdf"
                        id="exampleFormControlFile1"
                        onChange={convertFile}
                      />
                      Upload a file
                    </label>
                  </div>
                </div>
                <div>
                  <p className="text-muted mt-3">
                    Maximum size allowed is 10MB. Supported formats are:{" "}
                    {id.split("-")[0]}
                  </p>
                  {message && (
                    <div className="text-success" style={{ color: "green" }}>
                      {message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <Loader title={"Converting " + replaceId(id)} />
          ) : (
            <div className="w-100 d-flex justify-content-between main-container flex-wrap">
              <div className="images-side ml-5">
                <PdfViewer
                  currentPage={1}
                  forSplit="yes"
                  splitMode={splitMode}
                  rangeType={rangeType}
                  customRange={customRange}
                  fixedRange={fixedRange}
                  extractType={extractType}
                  pagesToExtract={pagesToExtract}
                  fileUrl={URL.createObjectURL(files[0])}
                  numPages={numPages}
                  setNumPages={setNumPages}
                />
              </div>
              <div
                className="converter-side p-3 d-flex flex-column justify-content-start"
                style={{ height: "88vh" }}
              >
                <h1 className="text-center mb-2">
                  {id.replace(/-/g, " ").toUpperCase()}
                </h1>
                <hr className="my-3" />
                <h6 className="text-center mb-3">
                  Selected pages will be converted into separate PDF files. 33
                  PDFs will be created.
                </h6>
                <hr className="my-3" />
                <div className="d-flex justify-content-center align-items-center flex-column mt-4">
                  <div className="d-flex justify-content-center gap-4 ccc">
                    <button
                      className="btn d-flex flex-column align-items-center box"
                      onClick={() => setSplitMode("split")}
                    >
                      <img
                        src={split}
                        alt="Split Option 1"
                        className="imgSplit mb-2"
                      />
                      <span>Split by Range</span>
                    </button>
                    <button
                      className="btn d-flex flex-column align-items-center box"
                      onClick={() => setSplitMode("extract")}
                    >
                      <img
                        src={split2}
                        alt="Split Option 2"
                        className="imgSplit mb-2"
                      />
                      <span>Extract Pages</span>
                    </button>
                  </div>
                  {splitMode === "split" && (
                    <>
                      <h5 className=" mt-2">Range Mode</h5>
                      <div className="d-flex justify-content-center gap-1 mt-2">
                        <button
                          className={`btnc btn ${
                            rangeType === "custom" ? "active" : ""
                          }`}
                          onClick={() => setRangeType("custom")}
                        >
                          Custom Range
                        </button>
                        <button
                          className={`btnc btn ${
                            rangeType === "fixed" ? "active" : ""
                          }`}
                          onClick={() => setRangeType("fixed")}
                        >
                          Fixed Range
                        </button>
                      </div>
                    </>
                  )}
                  {splitMode === "extract" && (
                    <>
                      <h5 className="text-center mt-2">Extract Mode</h5>
                      <div className="d-flex justify-content-center gap-1 mt-2">
                        <button
                          className={`btnc btn ${
                            rangeType === "extractAll" ? "active" : ""
                          }`}
                          onClick={() => {
                            setRangeType("extractAll");
                            setExtractType("");
                            setPagesToExtract("");
                          }}
                        >
                          Extract All Pages
                        </button>
                        <button
                          className={`btnc btn ${
                            extractType === "select" ? "active" : ""
                          }`}
                          onClick={() => {
                            setExtractType("select");
                            setRangeType(""); // Ensure rangeType doesn't conflict
                          }}
                        >
                          Select Pages
                        </button>
                      </div>
                    </>
                  )}
                  {rangeType === "custom" && (
                    <div className="row justify-content-center mt-4">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="fromRange" className="form-label">
                            From
                          </label>
                          <input
                            id="fromRange"
                            type="number"
                            className="form-control"
                            placeholder="From"
                            value={customRange.from}
                            onChange={(e) => {
                              const value = Math.max(0, e.target.value); // Ensure value is not negative
                              setCustomRange({
                                ...customRange,
                                from: value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="toRange" className="form-label">
                            To
                          </label>
                          <input
                            id="toRange"
                            type="number"
                            className="form-control"
                            placeholder="To"
                            value={customRange.to}
                            onChange={(e) => {
                              const value = Math.max(0, e.target.value);
                              setCustomRange({
                                ...customRange,
                                to: value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {rangeType === "fixed" && (
                    <div className="row justify-content-center mt-4">
                      <div className="form-group">
                        <label htmlFor="fixedRange" className="form-label">
                          Page Range
                        </label>
                        <input
                          id="fixedRange"
                          type="number"
                          className="form-control"
                          placeholder="Page Range"
                          value={fixedRange}
                          onChange={(e) => {
                            const value = Math.max(0, e.target.value);
                            setFixedRange(value);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {extractType === "select" && splitMode === "extract" && (
                    <div className="row justify-content-center mt-4">
                      <div className="form-group">
                        <label
                          htmlFor="extractPagesRange"
                          className="form-label"
                        >
                          Pages to extract:
                        </label>
                        <input
                          id="extractPagesRange"
                          type="text"
                          className="form-control"
                          placeholder="Enter page numbers or range (e.g., 1-3,5,7)"
                          value={pagesToExtract}
                          onChange={(e) => setPagesToExtract(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-auto">
                  <button
                    className="btn-convert w-100 mt-4"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner />
                    ) : (
                      <span>
                        Split PDF{" "}
                        <img src={rightarrow} alt="" width="30" height="18" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {files.length === 0 ? (
        <>
          <FileFooter id={id} />
          <Footer />
        </>
      ) : null}
    </>
  );
};

export default SplitPdf;
