import React, { useState } from "react";
import Imagebox from "../pdfConveter/Imagebox";
import rightarrow from "../../assets/rightarrow.png";
import FileHeader from "../file-header/FileHeader";
import FileFooter from "../file-footer/FileFooter";
import Footer from "../footer/Footer";
import noMargin from "../../assets/marginsetIcon.svg";
import bigimg from "../../assets/big-margin-icon.png";
import smallMargin from "../../assets/small-margin-icon.svg";
import { api } from "../../api/api";
import Loader from "../loader/Loader";
import { replaceId, MAX_FILE_SIZE } from "../../shared";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { IconGripVertical } from "@tabler/icons-react";
import InputWithIcons from "../InputWithIcons";
import PdfViewer from "../PdfViewr";

const EditPdf = ({ id, isSingle }) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  //   const [targetText, setTargetText] = useState("");
  //   const [newText, setNewText] = useState("")
  const [formData, setFormData] = useState({
    targetText: "",
    newText: "",
  });
  const navigate = useNavigate();
  const convertFile = async (event) => {
    setMessage("");
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handlePageOrientationClick = (event, orientation) => {
    event.preventDefault();
    setFormData((prevData) => ({ ...prevData, orientation }));
  };

  const handleMarginSelection = (event, margin) => {
    event.preventDefault();
    setFormData((prevData) => ({ ...prevData, margin }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSwitchChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      isMergeAll: event.target.checked,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!files) {
      toast.error("Please select a file first.");
      return;
    }

    if (files?.length > 10) {
      toast.error("File must be less than or equal to 10.");
      return;
    }

    const invalidFiles = files.filter((file) => file.size > MAX_FILE_SIZE);
    if (invalidFiles.length > 0) {
      toast.error("One or more files exceed the 10 MB limit.");
      return;
    }

    setLoading(true);
    const formDataToSend = new FormData();
    Object.keys(formData)?.forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    formDataToSend.append("file", files[0]);
    try {
      const response = await api().post(`${id}/single`, formDataToSend);
      setLoading(false);
      if (response?.data?.meta.status) {
        navigate("/convertor/file-ready", {
          state: { fileUrl: response?.data?.data?.url },
        });
      }
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };
  return (
    <>
      {files.length === 0 ? (
        <>
          <FileHeader id={id} />
          <div className="d-flex justify-content-center">
            <div className="main-box d-flex align-items-center justify-content-center mt-5">
              <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
                <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                  <h3>Drop your file here</h3>
                  <span>or</span>
                  <div>
                    <label
                      id="idlabel"
                      className="mdl-button mdl-js-button mdl-button--raised"
                    >
                      <input
                        type="file"
                        accept=".pdf"
                        multiple={!isSingle}
                        id="exampleFormControlFile1"
                        onChange={convertFile}
                      />
                      Upload a file
                    </label>
                  </div>
                </div>
                <div>
                  <p className="text-muted mt-3">
                    Maximum size allowed is 10MB. Supported formats are :{" "}
                    {id.split("-")[0]}
                  </p>
                  {message && (
                    <div className="text-success" style={{ color: "green" }}>
                      {message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <Loader title={"Updating pdf "} />
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="w-100 d-flex justify-content-between main-container flex-wrap pt-5">
                <div className="images-side ml-5">
                  <PdfViewer fileUrl={URL.createObjectURL(files[0])} {...{numPages, setNumPages}} />
                  {/* <Imagebox files={files} setFiles={setFiles} isSingle={isSingle} /> */}
                </div>
                <div className="converter-side p-3">
                  <h1 className="text-center">
                    {id.replace(/-/g, " ").toUpperCase()}
                  </h1>
                  <hr style={{ borderColor: "#FF9291", mx: "-16px" }} />
                  <p style={{ fontSize: "18px" }} className="my-4">
                    Copy the text that you want to edit from left pane and paste
                    below.
                  </p>
                  <hr style={{ borderColor: "#FF9291" }} />
                  <div className="d-flex justify-content-between mt-3">
                    <p>Page 1</p>
                    <a
                      className="link-underline-danger"
                      style={{ color: "#FF6E6C" }}
                    >
                      Remove all
                    </a>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <IconGripVertical />
                    <InputWithIcons
                      ph="Paste text here"
                      value={formData.targetText}
                      onChange={(e) =>
                        setFormData({ ...formData, targetText: e.target.value })
                      }
                    />
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-2">
                    <IconGripVertical />
                    <InputWithIcons
                      ph="Type here the edited version"
                      value={formData.newText}
                      onChange={(e) =>
                        setFormData({ ...formData, newText: e.target.value })
                      }
                    />
                  </div>
                  {/* <hr style={{ borderColor: "#FF9291" }} className="my-4" />
                                <div className="d-flex justify-content-between mt-3">
                                    <p>Page 1</p>
                                </div>
                                <div className="d-flex align-items-center gap-3">
                                    <IconGripVertical />
                                    <InputWithIcons />
                                </div>
                                <div className="d-flex align-items-center gap-3 mt-2 mb-5">
                                    <IconGripVertical />
                                    <InputWithIcons />
                                </div>*/}
                  <div>
                    <button type="submit" className="btn-convert w-100 mt-5">
                      Update PDF{" "}
                      <img src={rightarrow} alt="" width="30" height="18" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </>
      )}
      {files.length === 0 ? (
        <>
          <FileFooter id={id} />
          <Footer />
        </>
      ) : (
        <></>
      )}
      <ToastContainer />
    </>
  );
};

export default EditPdf;
